import React, { useEffect, useRef } from 'react';
import './App.css';

const App = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const text = "macavity's katopia: purrrrrrrrrrfect chaos unfurled...♥︎";

    const p0 = { x: 16, y: 0 };
    const p1 = { x: 256, y: 0 };
    const p2 = { x: 496, y: 0 };

    let phase = 0.0;

    const animate = () => {
      p0.y = Math.sin(phase * 0.013) * 240 + 256;
      p1.x = Math.sin(phase * 0.031) * 64 + 256;
      p1.y = Math.sin(phase * 0.027) * 240 + 256;
      p2.y = Math.sin(phase * 0.022) * 240 + 256;
      phase += 1.0;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.font = '18px Open Sans';
      context.textBaseline = 'alphabetic';
      context.fillStyle = '#FFF';

      let t = 0.0;
      let i = 0;
      let px, py, vx, vy, vl;
      const letterSpacing = 1.0;
      let char = text.charAt(0);
      let charWidth = context.measureText(char).width + letterSpacing;

      while (true) {
        px = (1 - t) ** 2 * p0.x + 2 * t * (1 - t) * p1.x + t ** 2 * p2.x;
        py = (1 - t) ** 2 * p0.y + 2 * t * (1 - t) * p1.y + t ** 2 * p2.y;
        vx = 2 * t * (p0.x - 2 * p1.x + p2.x) - 2 * (p0.x - p1.x);
        vy = 2 * t * (p0.y - 2 * p1.y + p2.y) - 2 * (p0.y - p1.y);
        vl = Math.sqrt(vx ** 2 + vy ** 2);

        context.save();
        context.translate(px, py);
        context.rotate(Math.atan2(vy, vx));
        context.fillText(char, -charWidth * 0.5, 0);
        context.restore();

        if (i < text.length) {
          t += charWidth * 0.5 / vl;
          char = text.charAt(++i);
          charWidth = context.measureText(char).width + letterSpacing;
          t += charWidth * 0.5 / vl;
        } else {
          break;
        }
      }

      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <div className="App">
      <canvas ref={canvasRef} width="512" height="512" />
    </div>
  );
};

export default App;